import React, { useCallback, useRef } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, Login, Background, LoginContent } from './styles';

import logoImg from '../../assets/logo.png';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ email: data.email, password: data.password });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
        addToast({
          type: 'error',
          title: 'Falha ao fazer o login',
          description: 'Verifique suas credencias',
        });
      }
    },
    [signIn, history, addToast],
  );

  return (
    <Container>
      <Content>
        <Login>
          <LoginContent>
            <img src={logoImg} alt="IntelMax" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Faça seu login</h1>

              <Input icon={FiMail} placeholder="E-mail" name="email" />

              <Input
                icon={FiLock}
                placeholder="Senha"
                name="password"
                type="password"
              />

              <Button type="submit">Entrar</Button>
            </Form>
          </LoginContent>
        </Login>

        <Background />
      </Content>
    </Container>
  );
};

export default SignIn;
