import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 40px;
  width: 300px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 2px solid #abb5a8;
  padding: 10px;
  color: #abb5a8;

  ${props =>
    props.isErrored &&
    css`
      border: 2px solid #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #00ac00;
      border: 2px solid #00ac00;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #00ac00;
    `}


  input {
    border: 0;
    flex: 1;
    padding-left: 10px;
    background: transparent;
  }
`;

export const Error = styled(Tooltip)`
  height: 18px;

  svg {
    margin-left: 10px;
  }
`;
