import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }

  span {
    visibility: hidden;
    position: absolute;
    width: max-content;
    bottom: calc(100%);
    opacity: 0;
    transition: opacity 0.4s;
    /* left: 50%;
    transform: translateX(-50%); */
    background: #c53030;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
  }
`;
