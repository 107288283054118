import styled, { css } from 'styled-components';

interface AuthorizedNameProps {
  hasName: boolean;
}

interface ColumnSafesProps {
  safeSelected: number;
}

export const Container = styled.div`
  background: #f4f4f4;
`;

export const Header = styled.header`
  padding: 30px 0;
  background: #212029;
  color: #fff;
  height: 10vh;
  display: flex;
  align-items: center;
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 72%;
  margin: 0 auto;

  > img {
    max-width: 200px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;

  div {
    a {
      text-decoration: none;
      color: #abb5a8;
      font-weight: 900;
    }
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 16px;
    color: #fff;

    &:hover {
      color: #abb5a8;
      transition-duration: 0.2s;
    }
  }
`;

export const Content = styled.main`
  flex: 1;
  max-width: 72%;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  background: #f4f4f4;
  min-height: 90vh;
  justify-content: space-between;
`;

export const ColumnSafes = styled.div<ColumnSafesProps>`
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  button {
    background: transparent;
    margin-bottom: 10px;
    border: 1px solid #21202925;
    border-radius: 5px;
    padding: 10px 5px;
    /* text-align: left; */
    font-size: 18px;
    font-weight: 500;
    width: 220px;

    display: flex;
    align-items: center;

    svg {
      margin-left: auto;
      min-width: 20px;
    }

    &:hover {
      color: #00ac00;
      border-color: #00ac00;
    }
  }

  ${props => {
    if (props.safeSelected >= 0) {
      return css`
        div:nth-of-type(${props.safeSelected + 1}) {
          button {
            color: #00ac00;
            border-color: #00ac00;
          }
        }
      `;
    }

    return css``;
  }}
`;

export const ColumnAuthorized = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: auto; */
  margin: 20px 0;

  h2 {
    font-size: 22px;
  }
`;

export const AuthorizedInfo = styled.div`
  display: flex;
  align-items: center;
  color: #212029;
  width: 500px;
  padding: 10px 0 10px 10px;
  border: 1px solid #21202925;
  border-radius: 5px;
  margin-top: 10px;

  &:hover {
    border: 1px solid #73c98e;

    div:last-child p:last-of-type {
      background: #00ac00;
      color: #fff;
    }
  }
`;

export const AuthorizedHeader = styled.div`
  p {
    color: #21202985;
    line-height: 1.5;
  }
`;
export const AuthorizedData = styled.div`
  margin-left: 20px;
  flex: 1;
  p {
    line-height: 1.5;
  }
`;

export const AuthorizedName = styled.div<AuthorizedNameProps>`
  display: flex;
  align-items: center;
  font-weight: 900;

  p {
    ${props =>
      props.hasName
        ? css`
            color: #00ac00;
          `
        : css`
            color: #21202985;
          `}
  }

  svg {
    cursor: pointer;
    transition-duration: 0.2s;
    &:hover {
      color: #00ac00;
      transition-duration: 0.2s;
    }
  }

  svg:first-of-type {
    margin-left: auto;
  }

  svg + svg {
    margin-left: 5px;
  }

  input {
    background: #f4f4f4;
    padding: 0 3px;
    border: 1px solid #21202985;
    border-radius: 3px;
    margin: 1px 0 2px;
  }
`;

export const AuthorizedEdition = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;

  p {
    background: #ccc;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    right: 0px;
    top: 35px;
    border-radius: 5px 0 5px 0;
    font-weight: 400;
    color: #21202985;
    letter-spacing: 1px;
  }
`;

export const ColumnSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Commands = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 40px; */
  margin-top: 20px;

  h2 {
    font-size: 22px;
  }

  span {
    margin-top: 10px;
    width: 300px;
    color: #747474;
  }
`;

export const CommandsTable = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border: 1px solid #21202925;
  border-radius: 5px;
  margin-top: 10px;

  button {
    margin-bottom: 0;
  }
`;

export const Extras = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    padding: 15px 10px;
    border: 1px solid #21202925;
    border-radius: 5px;
    margin-top: 10px;

    h3 {
      display: flex;
    }

    strong {
      margin: 0 auto;
      color: #21202965;
    }

    button {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  h2 {
    font-size: 22px;
  }
`;

export const FormDoorOptions = styled.div`
  display: flex;
  margin-bottom: 10px;

  h3 {
    font-size: 18px;
  }

  select {
    margin-left: auto;
    width: 50%;
    border: 1px solid #21202925;
    border-radius: 5px;
    background: #f4f4f4;

    option {
      border: 1px solid #21202925;
      border-radius: 5px;
      background: #f4f4f4;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding: 15px 0;
  background: rgb(33, 32, 41, 0.9);
  color: #fff;
`;

export const FooterContent = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  max-width: 72%;
  margin: 0 auto;
`;
