import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRightCircle, FiEdit, FiSave, FiXSquare } from 'react-icons/fi';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import api from '../../services/api';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  Container,
  Header,
  HeaderContent,
  Navigation,
  Content,
  ColumnSafes,
  ColumnAuthorized,
  AuthorizedInfo,
  AuthorizedHeader,
  AuthorizedData,
  AuthorizedName,
  AuthorizedEdition,
  ColumnSide,
  Commands,
  CommandsTable,
  Extras,
  FormDoorOptions,
  Footer,
  FooterContent,
} from './styles';
import logoImg from '../../assets/logo_w.png';
import manualFile from '../../assets/manual_armario_inteligente.pdf';

interface ISafeData {
  id: string;
  serial_number: string;
  name: string | null;
  company_owner: string | null;
  location: string | null;
  blocked_doors: string;
  authorized_safes: {
    id: string;
    name: string | null;
    cpf: string;
    card_number: string;
    has_bio: boolean;
    authorized_type: number;
    inEditionMode: boolean;
  }[];
}

interface IUpdateAuthorizedName {
  cpf: string;
  name: string;
  by_site: boolean;
}

const Dashboard: React.FC = () => {
  const [safeData, setSafeData] = useState<ISafeData[]>([]);
  const [safeCurrent, setSafeCurrent] = useState<ISafeData>();
  const [safeCurrentPos, setSafeCurrentPos] = useState(-1);

  const inputNameRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);
  const selectDoorRef = useRef<HTMLSelectElement>(null);
  const selectTimeRef = useRef<HTMLSelectElement>(null);
  // const { fieldName, defaultValue, registerField, error } = useField(
  //   'commandDoor',
  // );

  const { signOut } = useAuth();
  const { addToast } = useToast();

  const handleSafeData = useCallback(() => {
    try {
      api
        .get<ISafeData[]>('/safes')
        .then(response => {
          const safeDataUpdated: ISafeData[] = response.data.map(safe => {
            const authorizedSafeUpdated = safe.authorized_safes.map(auth => {
              return {
                ...auth,
                inEditionMode: false,
              };
            });
            return {
              ...safe,
              authorized_safes: authorizedSafeUpdated,
            };
          });

          safeDataUpdated.sort((a, b) => {
            if (!a.company_owner || !b.company_owner) {
              return 0;
            }
            if (a.company_owner < b.company_owner) {
              return 1;
            }
            if (a.company_owner > b.company_owner) {
              return -1;
            }
            return 0;
          });

          setSafeData(safeDataUpdated);
        })
        .catch(err => {
          if (err.response.status === 401) {
            addToast({
              type: 'error',
              title: 'Sessão expirada',
              description: 'Faça login novamente para utilizar o sistema',
            });
            signOut();
          }
        });
    } catch (err) {
      // console.log(err);
    }
  }, [signOut, addToast]);

  // const handleSaveName = useCallback(() => console.log('Salvando'), []);

  const handleSaveName = useCallback(
    async authorizedId => {
      if (safeCurrent && inputNameRef.current) {
        let data = {} as IUpdateAuthorizedName;

        const safesModified = safeData.map(safe => {
          if (safeCurrent.id !== safe.id) {
            return safe;
          }

          const newAuthorizedSafes =
            safeCurrent.authorized_safes.map(authorized => {
              if (authorized.id === authorizedId && inputNameRef.current) {
                const name = inputNameRef.current.value;

                const updatedAuthorized = {
                  ...authorized,
                  inEditionMode: false,
                  name,
                };

                data = {
                  cpf: authorized.cpf,
                  name,
                  by_site: true,
                };

                return updatedAuthorized;
              }

              return { ...authorized, inEditionMode: false };
            }) || [];

          // await Promise.all(newAuthorizedSafes);

          if (data) {
            api
              .post('/authorized', data)
              .then(() => {
                addToast({
                  type: 'success',
                  title: 'Autorizado atualizado com sucesso!',
                });
              })
              .catch(() => {
                addToast({
                  type: 'error',
                  title: 'Erro no cadastro',
                  description:
                    'Ocorreu um erro ao alterar o nome, tente novamente',
                });
              });
          }

          return { ...safeCurrent, authorized_safes: newAuthorizedSafes };
          // setSafeData({ ...safeData, safeCurrent: newAuthorizedSafes });
        });

        await Promise.all(safesModified);

        setSafeData(safesModified);
      }
    },
    [addToast, safeCurrent, safeData],
  );

  const handleToggleEdition = useCallback(
    async authorizedId => {
      if (safeCurrent) {
        const safesModified = safeData.map(safe => {
          if (safeCurrent.id !== safe.id) {
            return safe;
          }
          // Caso seja o armário da vez
          const authorizedModified = safe.authorized_safes.map(auth => {
            if (auth.id === authorizedId) {
              const updatedAuthorized = {
                ...auth,
                inEditionMode: !auth.inEditionMode,
              };

              return updatedAuthorized;
            }
            return { ...auth, inEditionMode: false };
          });

          return { ...safe, authorized_safes: authorizedModified };
        });

        await Promise.all(safesModified);

        setSafeData(safesModified);
      }

      // setSafeData({ ...safeData, authorized_safes: newAuthorizedSafes });
    },
    [safeCurrent, safeData],
  );

  const handleCommandSubmit = useCallback(async () => {
    if (!safeCurrent) {
      return;
    }
    try {
      const door = selectDoorRef.current?.value || '01';
      const time = selectTimeRef.current?.value || '01';

      const schema = Yup.object().shape({
        door: Yup.mixed().oneOf([
          '01',
          '02',
          '03',
          '05',
          '06',
          '07',
          '08',
          '99',
        ]),
        time: Yup.mixed().oneOf(['01', '02', '03', '05', '10', '15']),
      });

      await schema.validate(
        { door, time },
        {
          abortEarly: false,
        },
      );

      const safe_sn = safeCurrent?.serial_number;
      const command = door?.concat(time);
      await api.post('/safes/command', { safe_sn, command });

      addToast({ type: 'success', title: 'Comando enviado com sucesso!' });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Falha no envio',
          description:
            'Certifique-se de ter escolhido dentre uma das opções corretas',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Falha no envio',
          description:
            'Algo ocorreu no envio e recebimento do comando, tente novamente',
        });
      }
    }
  }, [selectDoorRef, addToast, safeCurrent]);

  useEffect(() => {
    handleSafeData();
  }, [handleSafeData]);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const authorized =
    useMemo(() => {
      const safe = safeData.find(s => s.id === safeCurrent?.id);

      if (safe) {
        return safe.authorized_safes.map(auth => {
          return {
            id: auth.id,
            name: auth.name ? auth.name : 'Não cadastrado',
            has_name: !!auth.name,
            cpf: `${auth.cpf.substring(0, 3)}.${auth.cpf.substring(
              3,
              6,
            )}.${auth.cpf.substring(6, 9)}-${auth.cpf.substring(9)}`,
            card_number: auth.card_number,
            bio: auth.has_bio ? 'sim' : 'não',
            authorized_type:
              auth.authorized_type === 1 ? 'Colaborador' : 'Gerente',
            inEditionMode: auth.inEditionMode,
          };
        });
      }

      return undefined;
    }, [safeData, safeCurrent]) || [];

  return (
    <Container>
      <Header>
        <HeaderContent>
          <img src={logoImg} alt="IntelMax" />
          <Navigation>
            <div>
              <Link className="active" to="/">
                Home
              </Link>
            </div>

            <button type="button" onClick={() => signOut()}>
              Sair
            </button>
          </Navigation>
        </HeaderContent>
      </Header>
      <Content>
        <ColumnSafes safeSelected={safeCurrentPos}>
          <h1>Armários</h1>
          {safeData.map((safe, index) => (
            <div key={safe.id}>
              {index === 0 ||
              safe.company_owner !== safeData[index - 1].company_owner ? (
                <h3>{safe.company_owner}</h3>
              ) : (
                ''
              )}
              <button
                type="button"
                onClick={() => {
                  setSafeCurrent(safe);
                  setSafeCurrentPos(index);
                  handleToggleEdition('');
                }}
              >
                {safe.name}
                <FiArrowRightCircle />
              </button>
            </div>
          ))}
        </ColumnSafes>
        {safeCurrent && (
          <>
            <ColumnAuthorized>
              <h2>Autorizados</h2>
              {authorized.map(auth => {
                return (
                  <AuthorizedInfo key={auth.id}>
                    <AuthorizedHeader>
                      <p>Nome:</p>
                      <p>CPF:</p>
                      <p>Nº do Cartão:</p>
                      <p>Possui Biometria:</p>
                    </AuthorizedHeader>
                    <AuthorizedData>
                      <AuthorizedName hasName={auth.has_name}>
                        {!auth.inEditionMode ? (
                          <>
                            <p>{auth.name}</p>
                            <FiEdit
                              onClick={() => handleToggleEdition(auth.id)}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              ref={inputNameRef}
                              type="text"
                              name="name"
                              id={auth.id}
                              defaultValue={auth.has_name ? auth.name : ''}
                              placeholder={auth.has_name ? '' : auth.name}
                            />

                            <FiSave
                              onClick={() => {
                                handleSaveName(auth.id);
                              }}
                            />
                            <FiXSquare
                              onClick={() => handleToggleEdition(auth.id)}
                            />
                          </>
                        )}
                      </AuthorizedName>

                      <p>{auth.cpf}</p>
                      <p>{auth.card_number}</p>
                      <p>{auth.bio}</p>
                    </AuthorizedData>
                    <AuthorizedEdition>
                      <p>{auth.authorized_type}</p>
                    </AuthorizedEdition>
                  </AuthorizedInfo>
                );
              })}
            </ColumnAuthorized>

            <ColumnSide>
              <Commands>
                <h2>Envio de comandos</h2>
                <CommandsTable>
                  <Form ref={formRef} onSubmit={handleCommandSubmit}>
                    <FormDoorOptions>
                      <h3>Abrir a porta:</h3>
                      <select name="commandDoor" ref={selectDoorRef}>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="99">Todas (uma a uma)</option>
                      </select>
                    </FormDoorOptions>
                    <FormDoorOptions>
                      <h3>Pelo tempo de:</h3>
                      <select name="commandTime" ref={selectTimeRef}>
                        <option value="01">1 minuto</option>
                        <option value="02">2 minutos</option>
                        <option value="03">3 minutos</option>
                        <option value="05">5 minutos</option>
                        <option value="10">10 minutos</option>
                        <option value="15">15 minutos</option>
                      </select>
                    </FormDoorOptions>
                    <Button type="submit">Enviar</Button>
                  </Form>
                </CommandsTable>
                <span>
                  <strong>Observação:</strong> envie um comando e aguarde o
                  armário recebe-lo. Não existe fila de comandos, cada novo
                  comando sobreescreve o anterior, caso este ainda não tenha
                  sido lido pelo armário.
                </span>
              </Commands>
              <Extras>
                <h2>Extras</h2>
                <div>
                  <h3>
                    Portas bloqueadas:{' '}
                    <strong>{safeCurrent.blocked_doors}</strong>
                  </h3>
                  <Button>
                    <a
                      href={manualFile}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Baixar o manual de uso
                    </a>
                  </Button>
                </div>
              </Extras>
            </ColumnSide>
          </>
        )}
      </Content>
      <Footer>
        <FooterContent>
          <p>Intelmax Soluções Tecnológicas | Copyright © {currentYear}</p>
        </FooterContent>
      </Footer>
    </Container>
  );
};

export default Dashboard;
