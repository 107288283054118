import styled from 'styled-components';

import safesBackgroundImg from '../../assets/safe_bw.jpg';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 30px 0;
  background: #212029;
  color: #fff;
  height: 10vh;
  display: flex;
  align-items: center;
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;

  > img {
    max-width: 200px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;

  div {
    a {
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #abb5a8;
        transition-duration: 0.2s;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100vh;
  background: #212029;
`;

export const Login = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 50px 0px;
  padding: 0 40px; */
`;

export const LoginContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  /* height: 60%; */
  /* width: 45%; */
  border-radius: 5px;
  padding: 50px 30px 30px;

  img {
    max-width: 250px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 2px solid #abb5a8;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      /* font-size: 36px; */
      margin-bottom: 20px;
    }
  }
`;

export const Background = styled.aside`
  flex: 1;
  flex-grow: 1;
  background: url(${safesBackgroundImg}) no-repeat right;
  background-size: cover;
`;
