import styled from 'styled-components';

export const Container = styled.button`
  height: 40px;
  width: 300px;
  border-radius: 10px;
  border: 0;
  margin: 10px 0;
  color: #fff;
  font-weight: 900;
  background: #00ac00;
  transition-duration: 0.4s;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 1px;

  &:hover {
    background: #73c98e;
    transition-duration: 0.4s;
  }
`;
